<template>
  <div>
    <CRow>
      <CCol sm="4">
        <h4 class="text-uppercase">Customer Applications</h4>
      </CCol>

      <CCol sm="8"> </CCol>
    </CRow>

    <CRow>
      <CCol sm="12">
        <CCard>
          <CRow class="pb-3">
            <CCol sm="10">
              <form v-on:submit.prevent="filterApplication(filter)">
                <CRow>
                  <CCol sm="2" class="pr-0">
                    <CInput v-model="filter.name" placeholder="Customer Name" />
                  </CCol>
                  <CCol sm="2" class="pr-0">
                    <CInput
                      v-model="filter.mobile_number"
                      placeholder="Mobile No"
                    />
                  </CCol>
                  <CCol sm="2" class="pr-0">
                    <select class="form-control" v-model="filter.status">
                      <option value="">Select Status</option>
                      <option value="REQUESTED">REQUESTED</option>
                      <option value="APPROVED">APPROVED</option>
                      <option value="REJECTED">REJECTED</option>
                    </select>
                  </CCol>
                  <CCol sm="2" class="pr-0">
                    <CInput
                      v-model="filter.ward_number"
                      placeholder="Ward No"
                    />
                  </CCol>
                  <CCol sm="2">
                    <button class="btn btn-success w-100">Search</button>
                  </CCol>
                </CRow>
              </form>
            </CCol>

            <CCol sm="2" class="text-right">
              <router-link
                class="btn btn-primary"
                to="/customer-applications/create"
                v-if="checkUsrPermissions(['CApp_all', 'CApp_create'])"
              >
                + New Application
              </router-link>
            </CCol>
          </CRow>
          <CRow>
            <CCol sm="12">
              <div class="table-responsive">
                <table class="table table-borderless">
                  <thead>
                    <tr class="text-center">
                      <th scope="col" class="text-left">Account No</th>
                      <th scope="col" class="text-left">Name</th>
                      <th scope="col">Email</th>
                      <th scope="col">Mobile No</th>
                      <th scope="col">
                        Ward <br />
                        Number
                      </th>
                      <th scope="col">Category</th>
                      <th scope="col">Application <br> Status</th>
                      <th scope="col">Created At</th>
                      <th scope="col">Action</th>
                    </tr>
                  </thead>
                  <tbody v-if="applications.length">
                    <tr
                      class="text-center"
                      v-for="app in applications"
                      :key="app.id"
                    >

                      <td scope="row" class="text-left">{{ app.account_number }}</td>

                      <td scope="row" class="text-left">
                        {{
                          app.salutation +
                            " " +
                            app.first_name +
                            " " +
                            app.last_name
                        }}
                      </td>
                      <td scope="row">{{ app.email }}</td>
                      <td scope="row">{{ app.mobile_number }}</td>
                      <!-- <td scope="row">{{ app.organization_name }}</td> -->
                      <td scope="row">{{ app.ward_number }}</td>
                      <td scope="row">{{ app.category }}</td>
                      <td scope="row">
                        <span class="badge" :class="app.status === 'APPROVED'?'badge-success':(app.status === 'REJECTED')?'badge-danger':'badge-primary'">
                          {{ app.status }}
                        </span>
                      </td>
                      <td scope="row">{{ dateFormat(app.created_at) }}</td>
                      <td scope="row">
                        <span
                          v-if="checkUsrPermissions(['CApp_all', 'CApp_show'])"
                          class="mb-1 pointer"
                          @click="$router.push('/customer-applications/' + app.id)"
                          shape="pill"
                        >
                          <i class="fa fa-eye fa-2x color-view"></i>
                        </span>


                        <span
                          class="mb-1"
                          color="secondary"
                          shape="pill"
                          @click="$router.push('/customer-applications/' + app.id + '/edit')"
                          v-if="checkUsrPermissions(['CApp_all', 'CApp_edit']) && app.status !== 'APPROVED'"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            xmlns:xlink="http://www.w3.org/1999/xlink"
                            fill="#5e76e7"
                            width="32"
                            height="32"
                            version="1.1"
                            id="lni_lni-pencil-alt"
                            x="0px"
                            y="0px"
                            viewBox="0 0 64 100"
                            style="enable-background: new 0 0 64 64"
                            xml:space="preserve"
                          >
                            <path
                              d="M62.7,11.2c0-0.7-0.3-1.3-0.8-1.8c-1.3-1.3-2.5-2.5-3.7-3.7c-1.1-1.1-2.2-2.2-3.3-3.4c-0.4-0.5-1-0.9-1.6-1  c-0.7-0.1-1.5,0.1-2.1,0.6l-7.2,7.2H8.7c-4.1,0-7.4,3.3-7.4,7.4v38.9c0,4.1,3.3,7.4,7.4,7.4h38.9c4.1,0,7.4-3.3,7.4-7.4V19.9  l6.9-6.9C62.4,12.5,62.7,11.8,62.7,11.2z M33.3,36.6c-0.1,0.1-0.3,0.2-0.4,0.3l-8.6,2.9l2.8-8.6c0.1-0.2,0.1-0.3,0.3-0.4l19-19  l6,5.9L33.3,36.6z M51.5,55.4c0,2.1-1.7,3.9-3.9,3.9H8.7c-2.1,0-3.9-1.7-3.9-3.9V16.4c0-2.1,1.7-3.9,3.9-3.9h31.9L24.9,28.2  c-0.5,0.5-0.9,1.1-1.1,1.8l-3.8,11.6c-0.2,0.6-0.1,1.2,0.2,1.7c0.3,0.4,0.7,0.8,1.6,0.8h0.3l11.9-3.9c0.7-0.2,1.3-0.6,1.8-1.1  l15.8-15.7V55.4z M54.8,15.1l-6-5.9l4-4c1,1,1.9,1.9,2.9,2.9c1,1,2,2,3,3.1L54.8,15.1z"
                            />
                          </svg>
                        </span>


                        <span
                          v-if="checkUsrPermissions(['CApp_all', 'CApp_delete']) && app.status === 'REQUESTED'"
                          class="mb-1 pointer"
                          @click="destroy(app)"
                          shape="pill"
                        >
                          <i class="fa fa-trash-o fa-2x text-danger" style="margin: 10px;"></i>
                        </span>
                      </td>

                    </tr>
                  </tbody>
                  <tbody v-else>
                    <tr>
                      <td colspan="8">
                        <h5 class="text-center">Data Not Available.</h5>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </CCol>
          </CRow>
          <CPagination
            v-if="applications.length"
            align="center"
            :pages="rows"
            :active-page.sync="filter.currentPage"
            @update:activePage="handlePageChange"
          />
        </CCard>
      </CCol>
    </CRow>


  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "CustomerAppComp",

  data() {
    return {
      filter: {
        mobile_number: "",
        name: "",
        ward_number: "",
        status: "",
        currentPage: 1,
      },
    };
  },
  methods: {
    dateFormat(date) {
      return moment(date).format("Do MMM, YYYY h:mm a");
    },
    filterApplication(search) {
      if (search) {
        search.currentPage = 1;
        this.$router.replace({name: "ApplicationList", query: search});
        this.$store.dispatch("CustomerApplication/getApplications", search);
      }
    },
    handlePageChange(value) {
      this.filter.currentPage = value;
      this.$router.replace({name: "ApplicationList", query: this.filter});
      this.$store.dispatch("CustomerApplication/getApplications", this.filter);
    },
    destroy(app) {
      this.$confirm({
        message: `Are you sure, want to delete this application?`,
        button: {
          no: "No",
          yes: "Yes, Delete",
        },
        callback: (confirm) => {
          if (confirm) {
            this.$store
              .dispatch("CustomerApplication/destroy", app.id)
              .then(
                () => {
                  this.$store.dispatch("Settings/loading", false);
                  this.$toastr.s(
                    "Customer's application successfuly deleted.",
                    "Deleted!"
                  );
                },
                (error) => {
                  this.$toastr.e(error.response.data.message, "Failed!");
                  this.$store.dispatch("Settings/loading", false);
                }
              );
          }
        },
      });
    },
    setSearchParams() {
      this.filter.mobile_number = this.$route.query.mobile_number?this.$route.query.mobile_number:'';
      this.filter.name = this.$route.query.name?this.$route.query.name:'';
      this.filter.ward_number = this.$route.query.ward_number?this.$route.query.ward_number:'';
      this.filter.status = this.$route.query.status?this.$route.query.status:'';
      this.filter.currentPage = this.$route.query.currentPage?parseInt(this.$route.query.currentPage):1;
    }
  },
  computed: {
    ...mapGetters("CustomerApplication", ["applications", "rows", "perPage"]),
  },
  mounted() {
    this.setSearchParams();
    this.$store.dispatch("CustomerApplication/getApplications", this.filter);
  },
};
</script>

<style scoped>
.card {
    border: none;
    padding: 15px 15px 0;
}
tbody:before {
  content: "@";
  display: block;
  line-height: 0;
  text-indent: -99999px;
}
.hide-footer >>> footer {
  display: none;
}
.custom-close-button >>> .close {
  color: aliceblue;
}
</style>

<style lang="scss" scoped>
.badge {
  font-size: 12px;
  padding: 5px 7px;
}
div {
  h4 {
    font-size: 15px;
    font-weight: bold !important;
    color: #000;
  }
  .table {
    font-size: 14px;

    thead {
      tr {
        th {
          background: #f8f9fa;
          color: #000;
          vertical-align: middle;
        }
      }
    }
    tbody {
      font-size: 13px;
      tr {
        color: #000;
        td {
          padding: 0.25rem 0 0.25rem 0.75rem;
          vertical-align: middle;
          button {
            font-size: 13px;
          }
          span {
            cursor: pointer;
          }
          span .color-view {
            color: #5e76e7;
            padding: 10px;
          }
        }
        td:nth-child(1) {
          color: #5e76e7;
        }
        td:nth-child(2) {
          font-weight: 500 !important;
        }
      }
    }
  }
}
</style>



